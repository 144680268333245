<template>
  <div class="login-page" ref="container">
    <div class="login-area">
      <div class="login-inner">
        <div class="logo-area">
          <img src="~@/assets/imgs/logo.png" alt="" />
        </div>
        <div class="title-area">上海君奕商务咨询有限公司</div>
        <el-form :model="model" :rules="formRules" ref="formRef">
          <el-form-item prop="userName">
            <el-input
              v-model="model.userName"
              placeholder="请输入用户名"
              @keyup.enter.native="onSubmit"
            >
              <template slot="append">
                <i style="font-size: 20px" class="el-icon-s-custom"></i
              ></template>
            </el-input>
          </el-form-item>
          <el-form-item prop="passWord">
            <el-input
              v-model="model.passWord"
              type="password"
              placeholder="请输入密码"
              @keyup.enter.native="onSubmit"
              autocomplete="new-password"
            >
              <template slot="append"
                ><i style="font-size: 20px" class="el-icon-key"></i
              ></template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="code">
              <el-input
                class="code-val"
                v-model="model.code"
                placeholder="请输入验证码"
                @keyup.enter.native="onSubmit"
              ></el-input>
              <el-button
                style="margin-left: 20px; width: 180px; font-size: 18px"
                @click="getCode"
                >{{ codes }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              class="login-btn"
              :loading="loading"
              type="primary"
              @click="onSubmit"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="auth-area">
      Copyright ©2021 上海君奕商务咨询有限公司｜<a
        href="https://beian.miit.gov.cn/"
        target="__blnk"
        >沪ICP备20020888号-1</a
      >
    </div>
  </div>
</template>

<script>
// import * as THREE from 'three'
// import { login } from '@/api/api'
import cryptoJs from 'crypto-js'
export default {
  data () {
    return {
      model: {
        userName: '',
        passWord: '',
      },
      codes: '',
      loading: false,
      formRules: {
        userName: [{ required: true, message: '请输入用户名', }],
        passWord: [{ required: true, message: '请输入密码', }],
        code: [{ required: true, message: '请输入验证码', }, { validator: this.validCode }],
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getCode()
    })
  },
  destroyed () {
  },
  methods: {
    onSubmit () {
      this.$refs.formRef.validate(valid => {
        if (!valid) { return false }
        this.loading = true
        this.$store
          .dispatch('user/login', { userName: this.model.userName, passWord: cryptoJs.MD5(this.model.passWord).toString() })
          .then(() => {
            this.$router
              .push({
                path: this.$route.query.redirect || '/',
              })
              .catch((error) => {
                alert(error)
              })
          }).catch(err => {
            this.loading = false
            this.$message.error(err)
            this.getCode()
          })
      })
    },
    validCode (rules, value, callback) {
      if (value.toLowerCase() !== this.codes.toLowerCase()) {
        return callback(new Error('验证码错误'))
      }
      callback()
    },
    getCode () {
      // 验证码组成库
      var arrays = [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
        'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
        'u', 'v', 'w', 'x', 'y', 'z',
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
        'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
        'U', 'V', 'W', 'X', 'Y', 'Z']
      this.codes = ''// 重新初始化验证码
      for (var i = 0; i < 4; i++) {
        // 随机获取一个数组的下标
        var r = parseInt(Math.random() * arrays.length)
        this.codes += arrays[r]
      }
    },
    login () {
      this.$store
        .dispatch('user/login', { username: this.username })
        .then(() => {
          this.$router
            .push({
              path: this.$route.query.redirect || '/',
            })
            .catch((error) => {
              alert(error)
            })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.login-page {
  background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
  // background: url('../assets/imgs/login-bg.png') no-repeat center center/100% 100%;
  height: 100vh;
  // background: linear-gradient(to bottom, #19778c, #095f88);
  // background-size: 1400% 300%;
  // animation: dynamics 6s ease infinite;
}
@keyframes dynamics {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}
.login-area {
  position: fixed;
  user-select: none;
  top: 50%;
  left: 50%;
  width: 470px;
  height: 470px;
  transform: translate(-50%, -50%);

  // background: linear-gradient(to bottom, #19778c, #095f88);
  // animation: dynamics 6s ease infinite;
  // -webkit-animation: dynamics 6s ease infinite;
  // -moz-animation: dynamics 6s ease infinite;
  opacity: 0.9;
  // border: solid 1px #13a1fc;
  background-size: 1400% 300%;
  .login-btn {
    width: 100%;
  }
  .login-inner {
    // background: linear-gradient(#13a1fc, #13a1fc) left top,
    //   linear-gradient(#13a1fc, #13a1fc) left top,
    //   linear-gradient(#13a1fc, #13a1fc) right top,
    //   linear-gradient(#13a1fc, #13a1fc) right top,
    //   linear-gradient(#13a1fc, #13a1fc) left bottom,
    //   linear-gradient(#13a1fc, #13a1fc) left bottom,
    //   linear-gradient(#13a1fc, #13a1fc) right bottom,
    //   linear-gradient(#13a1fc, #13a1fc) right bottom;
    // background-repeat: no-repeat;
    // background-size: 3px 20px, 20px 3px;
    height: 100%;
    margin: -2px;
    padding: 3px 60px 1px;
    border-radius: 3px;
  }
}
.title-area {
  font-size: 22px;
  color: #000;
  font-weight: 700;
  font-family: 'FangSong';
  text-align: center;
  height: 45px;
  line-height: 45px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.logo-area {
  text-align: center;
  user-select: none;
  background-color: #fff;
  border-radius: 50%;
  width: 95px;
  height: 95px;
  margin: 20px auto 0;
  img {
    width: 100%;
    height: 100%;
  }
}

::v-deep .el-input-group__append {
  background-color: #fff;
}
.code {
  display: flex;
  ::v-deep .el-button:focus,
  ::v-deep .el-button:hover {
    color: #333;
    border-color: none;
    background-color: #fff;
  }
}
.yaer-area {
  margin: auto;
}
.auth-area {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  cursor: default;
  color: rgba(0, 0, 0, 0.45);
  a {
    cursor: pointer;
  }
}
</style>
